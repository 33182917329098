<template>
  <v-container v-if="authUser">
    <my-wallet v-if="false" />

    <div class="display-1 text-center my-8">
      <v-avatar size="50" class="mr-2" contain tile>
        <v-img src="/img/flaticons/carts.png"></v-img>
      </v-avatar>
      <span>{{ isHindi ? `मेरे ऑर्डर्स` : `My Orders` }}</span>
    </div>

    <v-row class="justify-center" v-if="items && items.length > 0">
      <v-col cols="12" md="6" v-for="item in items" :key="item.id">
        <v-card
          @click="
            () => {
              $router.push({ name: 'invoice', params: { id: item.uid } });
            }
          "
          class="d-flex justify-space-between align-center"
        >
          <div>
            <v-card-title>
              {{ item.products[0] ? item.products[0].title : item.title }}
            </v-card-title>
            <v-card-text class="display-1">
              <div>₹ {{ item.amount / 100 }} /-</div>
              <small class="body-2"> {{ item.updated_at }} IST </small>
            </v-card-text>
          </div>
          <div
            class="overline mr-2 d-flex flex-column justify-center align-center"
          >
            <v-btn x-large icon>
              <v-icon
                color="green darken-3"
                large
                v-if="item.provider_status == 'paid'"
              >
                mdi-check-circle
              </v-icon>
              <v-icon
                color="orange"
                large
                v-if="item.provider_status == 'created'"
              >
                mdi-alert-circle-outline
              </v-icon>
              <v-icon
                color="red darken-3"
                large
                v-if="item.provider_status == 'attempted'"
              >
                mdi-alert-circle-outline
              </v-icon>
            </v-btn>
            <small>{{ item.provider_status.toUpperCase() }}</small>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else class="body-1 justify-center text-center">
      <v-col cols="12">
        {{ $lang("noitems") }}
      </v-col>
      <v-col cols="12">
        <v-btn
          @click="
            () => {
              $router.push({ name: 'products' });
            }
          "
          block
          outlined
          class="rounded-lg"
        >
          {{ $lang("BROWSESTORE") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { axios } from "@/plugins/axios";
import bus from "@/bus";
import MyWallet from "../modules/MyWallet.vue";
export default {
  name: "my-orders",
  components: {
    MyWallet,
  },

  data() {
    return {
      loading: false,
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),

    isHindi() {
      return this.$store.getters.getCurrLocale == "hi";
    },
  },
  watch: {},
  methods: {
    fetchOrders() {
      bus.$emit("showWait");
      return axios
        .get("orders/mine")
        .then((res) => {
          this.items = res.data.data;
          // this.items = data.filter((el) =>
          //   ["attempted", "paid", "pending"].includes(el.provider_status)
          // );
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
  },
  created() {
    this.fetchOrders();
  },
};
</script>
